<template>
  <div class="root">
    <Navigation active="0"/>
    <div id="section1">
      <video autoplay="autoplay" loop="loop" style="height:100%; width:100%; object-fit:cover;">
        <source src="/video/map.webm" type="video/webm">
      </video>
      <img id="topG" class="abs" src="/images/index/app_00.gif" style="right: 15%;">
      <img id="topP" class="abs" src="/images/index/yyfx.png" style="left: 15%;">
    </div>
<!--    <div id="section2">
      <div class="container">
        <img src="/images/index/app_06.jpg">
        <img src="/images/index/app_07.jpg">
        <img src="/images/index/app_08.jpg">
        <img src="/images/index/app_09.jpg">
        <img src="/images/index/app_10.jpg">
      </div>
    </div>-->
    <div id="section3">
      <div id="dlTitle">
        <div style="height: 93px; position: relative">
          <span style="display: inline-block; font-size: 44px; line-height: 93px">手抓地图&nbsp;·</span>
          <img style="position: absolute; " src="/images/index/kanluzhe-01.svg" height="100%">
        </div>
        <div style="font-size: 28px; line-height: 50px;">越野人的新发现</div>
        <div style="font-size: 18px; line-height: 30px;">支持离线地图 | 语音图文打点轨迹 | 分享你的发现</div>
      </div>
   
    </div>
  
<!--    <div id="section5">
      <div style="position: relative; top: 100px; width: 860px; margin: 0 auto;">
        <img style="position: absolute; top: 50px; left: 30px; box-shadow: -2px 2px 20px rgba(0, 0, 0, .4);"
             src="/images/index/app-dadian.jpg" height="350px">
        <img style="position: absolute; top: 30px; left: 170px; box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);"
             src="/images/index/app-dadian2.jpg" height="400px">
        <div style="position: absolute; top: 160px; right: 0px; width: 360px">
          <img src="/images/index/icon1.svg" width="50px" style="margin-right: 25px; ">
          <img src="/images/index/icon2.svg" width="50px">
          <div style="font-size: 30px; margin-top: 15px">一键记录轨迹,沿途打点</div>
          <div style="font-size: 14px; margin-top: 10px">用照片记位置，用语音讲故事，即使开车也能操作</div>
        </div>
      </div>
    </div>-->
    <div id="section6">
      <div style="position: relative; top: 100px; width: 860px; margin: 0 auto;">
        <img style="position: absolute; top: 50px; left: 465px; box-shadow: -2px 2px 20px rgba(0, 0, 0, .4);"
             src="/images/index/lixian.jpg" height="350px">
        <img style="position: absolute; top: 30px; left: 605px; box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);"
             src="/images/index/lixian2.jpg" height="400px">
        <div style="position: absolute; top: 160px; left: 0px">
          <img src="/images/index/icon5.svg" width="50px" style="margin-right: 25px; ">
          <img src="/images/index/icon6.svg" width="50px">
          <div style="font-size: 30px; margin-top: 15px">搜寻自驾路线</div>
          <div style="font-size: 14px; margin-top: 10px; line-height: 20px;">支持离线地图，在下载了离线地图后，支持断网<br>
            使用，可以设置有了Wi-Fi时自动同步
          </div>
        </div>
      </div>
    </div>
    <div id="section7">
      <div style="position: relative; width: 860px; margin: 0 auto; top: 100px">
        <img style="position: absolute; top: 50px; left: 30px; box-shadow: -2px 2px 20px rgba(0, 0, 0, .4);"
             src="/images/index/faxian.jpg" height="350px">
        <img style="position: absolute; top: 30px; left: 170px; box-shadow: 5px 5px 20px rgba(0, 0, 0, .4);"
             src="/images/index/faxian2.jpg" height="400px">
        <div style="position: absolute; top: 160px; right: 0px; width: 360px">
          <img src="/images/index/icon3.svg" width="50px" style="margin-right: 25px; ">
          <img src="/images/index/icon4.svg" width="50px">
          <div style="font-size: 30px; margin-top: 15px">勘路者</div>
          <div style="font-size: 14px; margin-top: 10px; line-height: 20px;">对于打点的照片，可以同时记录语音和文字，并可<br>
            在分享时用攻略文章的形式呈现和转发到微信
          </div>
        </div>
      </div>
    </div>
    <div id="section8">
      <div style="position: absolute; left: 20%; top: 240px">
        <div style="color: #fb0; font-size: 36px; margin-bottom: 30px">越野人的新发现</div>
        <div style="line-height: 30px; color: #999; font-size: 18px;">从草原到沙漠，从古镇到荒原，<br>
          适合高原等没有手机信号的地区察看地图和轨迹
        </div>
      </div>
    </div>
    <div id="section9">
      <div style="height: 1px; width: 100%; box-shadow: 0px 0px 20px 5px rgba(0,0,0,.4); margin-top: -4px; "></div>
      <div style="width: 930px; margin: 0 auto; position: relative">
        <img class="shdB" src="/images/index/bisai_1.jpg" width="480px">
        <div style="position: absolute; width: 340px; right: 0; top: 100px">
          <div style="padding-bottom: 20px; font-size: 30px;">赛事勘路</div>
          <div style="font-size: 14px;">T3英雄会、环塔拉力赛、玄奘之路等赛事，赛前
            勘路可以大大提高勘路效率，同时也能和佳明等
            专业GPS共享轨迹，可以快速把几条勘路结果组合
            成路网用于分析和提取赛道。
          </div>
          <div style="color: #888; font-size: 14px; padding-top: 20px">← 阿拉善</div>
        </div>
      </div>
    </div>
    <div id="section10">
      <div style="height: 1px; width: 100%; box-shadow: 0px 0px 20px 5px rgba(0,0,0,.4); margin-top: -4px; "></div>
      <div style="width: 1000px; height: 920px; margin: 0 auto; position: relative">
        <img src="/images/index/kanlu_1.jpg" width="480px"
             style="position: absolute; right: 40px; top: 0; box-shadow: -2px 2px 20px rgba(0, 0, 0, .4);">
        <div style="position: absolute; top: 370px; left: 30px">
          <div style="padding-bottom: 20px; font-size: 30px;">职业向导</div>
          <div style="font-size: 14px; letter-spacing: 1px; margin-bottom: 130px">活动领队，职业向导可用它制作收费路线<br><br>
            可以解决自己每个月工作时带队次数的限制，同时也不必总是<br>
            走着重复的路线，解放自己，可以把更多的精力放在开发新路<br>
            线产品上。
          </div>
          <div style="padding-bottom: 20px; font-size: 30px;">旅游达人</div>
          <div style="font-size: 14px; letter-spacing: 1px;">越野人、自驾车友、骑友、驴友等户外爱好者<br><br>
            可以记录自己的旅行轨迹，沿途可以用照片和语音记录并形成<br>
            基于位置的打点。旅行成就可以积累，路线能以路书的形式在<br>
            微信分享。
          </div>
        </div>
      </div>
      <div style="width: 960px; height: 825px; margin: 0 auto; position: relative">
        <img src="/images/index/heying.jpg" height="320px"
             style="box-shadow: -2px 2px 20px rgba(0, 0, 0, .4); margin-bottom: 100px">
        <div>
          <div style="margin-bottom: 120px; color: #fb0; font-size: 36px; text-align: center">我们不分享景点，<br>
            我们分享发现。
          </div>
        </div>
      </div>
      <div style="width: 100%; text-align: center">
        <img src="/images/index/jihe.png">
      </div>
    </div>
    <div id="section11">
      <div id="logo"
           style="width: 1122px; margin: 0 auto; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; padding-top: 100px; padding-bottom: 100px;">
        <img src="/images/index/logo-11.png">
        <img src="/images/index/logo-12.png">
        <img src="/images/index/logo-13.png">
        <img src="/images/index/logo-14.png">
        <img src="/images/index/logo-15.png">
        <img src="/images/index/logo-16.png">
        <img src="/images/index/logo-17.png">
        <img src="/images/index/logo-18.png">
        <img src="/images/index/logo-19.png">
        <img src="/images/index/logo-20.png">
        <img src="/images/index/logo-21.png">
        <img src="/images/index/logo-22.png">
        <img src="/images/index/logo-23.png">
        <img src="/images/index/logo-24.png">
        <img src="/images/index/logo-25.png">
        <img src="/images/index/logo-26.png">
        <img src="/images/index/logo-27.png">
        <img src="/images/index/logo-28.png">
        <img src="/images/index/logo-29.png">
        <img src="/images/index/logo-30.png">
        <img src="/images/index/logo-31.png">
        <img src="/images/index/logo-32.png">
        <img src="/images/index/logo-33.png">
        <img src="/images/index/logo-34.png">
      </div>
      <div style="position: relative">
        <img src="/images/logo_L.png" style="padding-left: 26px;">
        <div
            style="color: #888; font-size: 14px; line-height: 30px; text-align: center; position: absolute; width: 100%; top: 30px">
          Copyright © 2018 手抓地图 <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备16053603号</a>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Navigation";

export default {
  name: "App",
  components: {Navigation}
}
</script>

<style scoped>
#section1 {
  position: relative;
  height: 770px;
}

#section2 {
  height: 700px;
  background: #fed501;
}

#section2 .container {
  width: 1450px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

#section2 img {
  height: 609px;
}

#section3 {
  background-color: #fb0;
  height: 700px;
  position: relative;
  color: #333;
}

#section4 {
  height: 700px;
  background: black;
  color: #777;
  font-size: 12px;
}

#section5 {
  position: relative;
  height: 700px;
  background: #fb0;
}

#section6 {
  position: relative;
  height: 700px;
  background: #fff;
}

#section7 {
  position: relative;
  height: 700px;
  background: #fb0;
}

#section8 {
  position: relative;
  height: 700px;
  background: url("/images/index/chedui_1.jpg") no-repeat;
  background-size: 100%;
  background-position: bottom left;
  z-index: 1;
}

#section9 {
  position: relative;
  height: 700px;
  background: url("/images/index/brilliant.png");
  background-color: #2b2b2b;
  color: white;
  z-index: 1;
}

#section10 {
  height: 2550px;
  position: relative;
  color: white;
  background: url("/images/index/brilliant.png");
  background-color: #26292e;
}

#section11 {
  height: 855px;
  position: relative;
  background: url("/images/index/brilliant.png");
  background-color: #2b2b2b;
}

#logo img {
  width: 180px;
}

#topG {
  width: 240px;
  top: 105px;
  position: absolute;
}

#topP {
  top: 230px;
  width: 600px;
  position: absolute;
}

#dlTitle {
  position: absolute;
  margin-top: 260px;
  margin-left: 20%;
}

#dlDown {
  position: absolute;
  right: 20%;
  top: 200px;
}

.btn-down {
  color: black;
  text-align: center;
  width: 180px;
  height: 44px;
  line-height: 44px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  margin: 50px auto;
}

.videoDiv {
  padding-bottom: 20px;
}

.videoDiv p {
  text-align: center;
}

.videoDiv img {
  width: 450px;
}

#section4 .container {
  width: 1390px;
  margin: 0 auto;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
</style>